@use 'sass:math';

@font-face {
  src: url('Cornerstone.woff2') format(woff2);
  font-family: 'Cornerstone';
  font-weight: normal;
  font-style: normal;
}
@font-face {
  src: url('Abel-Regular.woff2') format(woff2);
  font-family: 'Abel-Regular';
  font-weight: normal;
  font-style: normal;
}

$main: 'Cornerstone';
$second: 'Abel-Regular';

// $green: #18f252;
// $blue: #18e7f2;
// $red: #f2182a;
// $navy: #1861f2;
// $purple: #5218f2;

* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style: none;
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-size: math.div(100vw, 1920) * 10;
}

.loader {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  background: rgba(7, 0, 19, 0.9);
  transition: 1.5s;
  pointer-events: none;

  .logo {
    width: 30%;
    transition: 1s;
  }
  .logo.out {
    filter: contrast(175%) brightness(3%) blur(4px);
    transform: scale(1.4);
  }
}
.av_wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);

  display: flex;
  justify-content: center;
  align-items: center;
  background: black;
}
.av_section {
  text-align: center;
  overflow: hidden;
  position: relative;
  height: 100%;
  flex: 1;
  cursor: pointer;
  transition: 0.5s;
  will-change: flex;
  transform-origin: center center;

  video {
    display: flex;
    position: absolute;
    flex: 1;
    height: 100%;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: 0.5s;
  }
  > * {
    pointer-events: none;
    user-select: none;
  }

  &:hover {
    img {
      transform: scale(1.1);
    }

    .av_content {
      bottom: 22%;
      transform: translateX(-50%) scale(1.05);

      p {
        opacity: 0;
      }
      h2 {
        animation: glitch 2s infinite;
      }
    }
  }

  .linear {
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: linear-gradient(0deg, rgba(4, 18, 40, 1) 0%, rgba(57, 62, 70, 0) 100%);
    transition: 0.5s;
    mix-blend-mode: multiply;
  }
  img {
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
    transition: 2s;
  }
  .av_content {
    position: absolute;
    bottom: 20%;
    left: 50%;
    height: 10rem;
    transform: translateX(-50%);
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    transition: 0.5s;

    h2 {
      text-transform: uppercase;
      font-family: $main;
      font-size: 5rem;
      letter-spacing: 0.2rem;
      transition: 1.5s;
      clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0% 100%);
      transform: translateY(20%);

      text-shadow: 0.5rem 0 0 rgba(255, 0, 0, 0.3), -0.25rem -0.05rem 0 rgba(0, 255, 0, 0.5), 0.25rem 0.25rem 0.25rem rgba(0, 0, 255, 0.7);
    }
    @keyframes glitch {
      0% {
        text-shadow: -0.5rem -0.25rem 0 rgba(255, 0, 0, 1), 0.25rem 0.05rem 0 rgba(0, 255, 0, 1), 0.25rem 0.25rem 0.25rem rgba(0, 0, 255, 1);
      }
      5% {
        text-shadow: -0.25rem 0.25rem 0.15rem rgba(255, 0, 0, 1), -0.25rem 0.25rem 0 rgba(0, 255, 0, 1), -0.15rem -0.15rem 0.25rem rgba(0, 0, 255, 1);
      }
      100% {
        text-shadow: 0.5rem -0.25rem 0 rgba(255, 0, 0, 1), -0.15rem -0.05rem 0 rgba(0, 255, 0, 1), -0.15rem -0.25rem 0.25rem rgba(0, 0, 255, 1);
      }
    }
    p {
      font-family: $second;
      opacity: 0.6;
      font-size: 1.5rem;
      display: inline-block;
      letter-spacing: 0.2rem;
      word-break: keep-all;
      width: 15rem;
      clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0% 100%);
      transform: translateY(20%);
      transition: 1.5s;
      margin-top: 4rem;
    }
    h2.active,
    p.active {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
      transform: translateY(0%);
    }
  }
}

.active {
  flex: 2;
}

.expand {
  flex: 2;
}
